import React, { useState, useEffect,useRef  } from 'react';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import axios from 'axios';

// Define your theme
const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#2cb2da',
  headerFontColor: '#fff',
  headerFontSize: '20px',
  botBubbleColor: '#217791',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
 
};

// Function to handle API call and return the response as a string
const handleApiCall = async (message) => {
  console.log("inside handleApiCall");
  try {
    const response = await axios.post('https://adminpanel.samuelbegin.info/chatbot', {
      message: message,
      // pdf_path: "https://chozha.easemypay.in/public/Corrom%20Tournament%20August%202024.pdf",
      // pdf_path: "https://chozha.easemypay.in/public/files/pdftest.pdf",
    });
    console.log("After fetching");
    // Ensure the API response is a string
    const reply = response.data.reply;
    return typeof reply === 'string' ? reply : 'Sorry, I didn’t get that.';
  } catch (error) {
    return 'Sorry, something went wrong. Please try again.';
  }
};

// Custom component to handle the API call
const CustomComponent = ({ previousStep, setChatbotState }) => {
  const [message, setMessage] = useState('');
  const prevMessageRef = useRef();

  useEffect(() => {
    console.log("Hi2");

    // Only make the API call if the message has changed
    if (prevMessageRef.current !== previousStep.value) {
      console.log("Message changed, calling API");
      prevMessageRef.current = previousStep.value; // Update ref to track the last message
      console.log("Ref update");
      const fetchReply = async () => {
        const reply = await handleApiCall(previousStep.value);
        console.log("handleApiCall");
        setMessage(reply);
        console.log("API response set in state");
        setChatbotState({ waitingForResponse: false });
      };

      fetchReply();
    }
  }, [previousStep.value, setChatbotState]);  // Adding necessary dependencies

  return <div>{message}</div>;
};

// Define your chatbot steps
const steps = [
  {
    id: '1',
    message: 'What would you like to know?',
    trigger: 'userInput',
  },
  {
    id: 'userInput',
    user: true,
    trigger: 'apiCall',
  },
  {
    id: 'apiCall',
    component: <CustomComponent />,
    asMessage:true,
    // user:true,
    // waitAction: true,
    trigger: 'userInput', // Move to the end step after API response
  }
];

// Chatbot component wrapped in ThemeProvider
const Bot = () => (
  <ThemeProvider theme={theme}>
    <ChatBot
      steps={steps}
      floating={true}
      headerTitle={"Pingu"}
      inputStyle={{ color: 'Black' }} 
    //   speechSynthesis={{ enable: true, lang: 'en' }}
      recognitionEnable={true}
      // Adding a handler to reset the waiting state when done
      handleEnd={() => console.log("Conversation ended")}
      
      
    />
  </ThemeProvider>
);

export default Bot;
